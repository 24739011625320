<template>
  <div id="work_page">
    <div class="content">
      <section class="head_text title-section text-left text-sm-center">
        <h1>my <span>portfolio</span></h1>
        <span class="title-bg d-none d-sm-block">works</span>
      </section>
      <div class="works_parent col-12 col-lg-10 offset-lg-1 mt-5 pb-5">
        <app-card></app-card>
      </div>
      <div class="codepen">
        <div class="tooltip_" v-if="hover">
          <p>Click to see more portfolio in "Codepen"</p>
          <span></span>
        </div>
        <div
          class="anker"
          @mouseover="hover = true"
          @mouseleave="hover = false"
        >
          <a href="https://codepen.io/maaatin/pens/public" target="_blank">
            <i class="fab fa-codepen"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cards from "./Portfolio/Cards";
export default {
  data() {
    return {
      hover: false,
    };
  },
  components: {
    appCard: Cards,
  },
};
</script>

<style scoped>
#work_page {
  width: 100%;
  min-height: 100vh;
  float: left;
}
.content {
  min-height: 100%;
  /* position: relative; */
}
.codepen {
  position: fixed;
  right: 25px;
  bottom: 10px;
}
.tooltip_ {
  width: 180px;
  height: 60px;
  position: relative;
  bottom: 85px;
  right: 0;
  border: 2px solid #d63031;
  border-radius: 15px;
  /* display: none; */
  transition: 1s;
}
.tooltip_ p {
  color: rgb(179, 179, 179);
  font-size: 12px;
  padding: 10px 15px;
}
.tooltip_ span {
  position: absolute;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 10px solid #d63031;
  top: 58px;
  right: 10px;
}
.anker {
  width: 50px;
  height: 50px;
  margin: 20px 0;
  border-radius: 50%;
  background: #2b2a2a;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: absolute;
  bottom: 0;
  right: 0;
  transition: background-color 0.1s ease-in;
  cursor: pointer;
  transition: 1s;
}
/* .anker:hover .tooltip_ {
  display: block;
} */
.anker a {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
  height: 100%;
  text-decoration: none;
  border-radius: 50%;
}
.anker i {
  font-size: 23px;
}
.anker a:hover {
  background: #d63031;
}
</style>
